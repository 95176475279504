<template>
  <div>
    <div class="session-box practice-session">
      <div class="header-box">
        <div class="header-options flex">
          <div class="header-right">
            <button class="header-right-button">
              <img :src="require(`../assets/button-arrow.svg`)" class="center-align" />
            </button>
            <div class="flex flex-column content-cntr" style="height: 34px">
              <span class="fw-700 fs-15 blk-text ln-20">Upload Answer Files</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex table-header">
        <div class="flex" style="width: 628px; margin-left: 52px !important">
          <span class="table-header-content" style="margin-right: 68px">Question No.</span>
          <span class="table-header-content" style="margin-right: 349px">Question</span>
          <span class="table-header-content">Upload Images</span>
        </div>
      </div>
      <div class="question-content" style="overflow-y: scroll; max-height: 420px">
        <div class="flex table-row" v-for="(data, index) in subQuesList" :key="`qn${index}`">
          <div class="flex flex-row" style="width: 140px">
            <span
              class="table-row-index center-align fs-18 fw-600 ln-20 flex flex-column content-cntr"
              >{{ index + 1 }}</span
            >
          </div>
          <div class="question-image">
            <img
              :src="data.questionDiagramURL"
              style="width: 350px !important; height: 40px !important"
              @click="showQuestionImage(data.questionDiagramURL)"
            />
          </div>
          <div class="flex">
            <div v-if="data.solutionUrlList.length === 0">
              <input
                type="file"
                id="file-uploader"
                style="display: none"
                accept=".jpg, .jpeg, .png"
                multiple
                ref="file"
                @change="onFileLogoChange($event)"
              /><button
                class="upload-button fw-600 fs-13 ln-18"
                @click="uploadSolution(data, index)"
              >
                UPLOAD
              </button>
            </div>
            <button v-if="data.solutionUrlList.length !== 0" class="concept-button-1">
              UPLOAD
            </button>
            <div style="margin-left: 16px" v-if="data.solutionUrlList.length > 0">
              <button
                class="upload-image"
                v-for="(img, index) in Object.values(data.solutionUrlList)"
                :key="`f${index}`"
                @click="showSolutionUploadImage(data.solutionUrlList)"
              >
                Image&nbsp;{{ index + 1 }}
              </button>
            </div>
            <div v-if="data.solutionUrlList.length !== 0" style="margin-top: 8px; margin-left: 5px">
              <img
                :src="require(`../assets/cross-icon.svg`)"
                @click="clearUploadImage(data.questionId)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-end submit-div">
        <button class="submit-button" @click="SubmitSubDataTypeQuestionList()">SUBMIT</button>
      </div>
    </div>
    <Modal2 :show="showImage" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="concept-modal">
          <div class="flex content-btw earn-head" style="padding-left: 10px; padding-right: 10px">
            <span class="fw-700 fs-18">Question Image</span>
            <div @click="showImage = false" style="cursor: pointer">
              <span class="material-icons earn-close" style="color: white; background: #ff7675"
                >close</span
              >
            </div>
          </div>
          <img :src="imageURL" />
        </div>
      </template>
    </Modal2>
    <div class="container">
      <div class="model-overlay" v-if="showSlider" @click="showSlider = false"></div>
      <div class="box" v-if="showSlider">
        <!-- <div class="imageBox" v-for="(item, index) in 3" :key="index">
                <img src="../assets/IMG1.png" class="img-content" alt="">
            </div> -->
        <div class="flex content-cntr">
          <img :src="`${solutionImage[index]}`" draggable="false" class="img-content" alt="" />
        </div>
        <div class="arrowtab">
          <img
            :src="require(`../assets/VectorLeft.png`)"
            alt=""
            @click="index -= 1"
            :class="{ viewImghide: index === 0 }"
          />
          <img
            :src="require(`../assets/VectorRight.png`)"
            alt=""
            @click="index += 1"
            :class="{ viewImghide: index === solutionImage.length - 1 }"
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="model-overlay" v-if="showSlider1" @click="showSlider1 = false"></div>
      <div class="box" v-if="showSlider1">
        <!-- <div class="imageBox" v-for="(item, index) in 3" :key="index">
                <img src="../assets/IMG1.png" class="img-content" alt="">
            </div> -->
        <div v-if="isImage === true" class="flex content-cntr">
          <img :src="`${uploadedImage[index1]}`" draggable="false" class="img-content" alt="" />
        </div>
        <div v-else class="flex content-cntr">
          <img
            :src="'data:image/jpg;base64,' + uploadedImage[index1].fileData"
            draggable="false"
            class="img-content"
            alt=""
          />
        </div>
        <div class="arrowtab">
          <img
            :src="require(`../assets/VectorLeft.png`)"
            alt=""
            @click="index1 -= 1"
            :class="{ viewImghide: index1 === 0 }"
          />
          <img
            :src="require(`../assets/VectorRight.png`)"
            alt=""
            @click="index1 += 1"
            :class="{ viewImghide: index1 === uploadedImage.length - 1 }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Modal2 from "../components/Model2.vue";
import Mobileapi from "../Mobileapi";

export default {
  data() {
    return {
      document,
      userTestSessionId: null,
      subQuesList: [],
      imageURL: "",
      showImage: false,
      showSlider: false,
      showSlider1: false,
      solutionImage: [],
      subjectiveQuesList: [],
      fileListFinal: [],
      imageTab: 0,
      index: 0,
      index1: 0,
      selectedQuestionId: null,
      selectedList: [],
      uploadedImage: [],
      subjectId: null,
      slToken: "",
      marks: null,
      Base64File: "",
      imageList: [],
      images: [],
      selectedIndex: null,
      selectedQuestionData: null,
      isImage: false,
      isLps: null,
    };
  },
  // beforeCreate() {
  //   document.querySelector("body").setAttribute("style", "background:#FFFFFF");
  // },
  // beforeUnmount() {
  //   document.querySelector("body").setAttribute("style", "background:#f6f1ec");
  //   localStorage.removeItem("IsLps");
  // },
  activated() {
    this.isLps = parseInt(this.$route.params.isLps);
    if (this.isLps) {
      this.isLps = parseInt(this.$route.params.isLps);
      localStorage.setItem("IsLps", this.isLps);
    } else {
      this.isLps = parseInt(localStorage.getItem("IsLps"));
    }
    this.$store.dispatch("showLoader", false);
    this.index = 0;
    this.index1 = 0;
    if (this.showSlider === false) {
      this.index = 0;
    }
    if (this.showSlider1 === false) {
      this.index1 = 0;
    }
    if (localStorage && localStorage.getItem("SLToken")) {
      this.slToken = localStorage.getItem("SLToken") || "";
    }
    // this.userTestSessionId = 1223422;
    this.userTestSessionId = this.$route.params.userTestSessionId;
    this.subjectId = this.$route.params.SubjectId;
    Mobileapi.GetSubjectQuestionList(this.userTestSessionId, (response) => {
      this.subQuesList = response.data;
    });
  },
  methods: {
    uploadSolution(data, index) {
      this.selectedQuestionId = data.questionId;
      this.selectedIndex = index;
      this.marks = data.maximummarks;
      this.selectedQuestionData = this.subQuesList[this.selectedIndex];
      document.getElementById("file-uploader").click();
    },
    showQuestionImage(source) {
      this.imageURL = source;
      this.showImage = true;
    },
    showSolutionImage(images) {
      this.showSlider = true;
      this.solutionImage = images.solutionUrlList;
    },
    showSolutionUploadImage(image) {
      const isValidUrl = (urlString) => {
        try {
          return Boolean(new URL(urlString));
        } catch (e) {
          return false;
        }
      };
      if (isValidUrl(image) === true) {
        this.isImage = true;
        this.showSlider = true;
        this.solutionImage = image;
      } else {
        this.isImage = false;
        this.showSlider1 = true;
        this.uploadedImage = image;
      }
    },
    clearUploadImage(questionId) {
      this.selectedList = this.subQuesList.filter((a) => a.questionId === questionId);
      this.selectedList[0].uploadedImages = [];
      this.selectedList[0].solutionUrlList = [];
      //   this.subQuesList.pop((a) => a.questionId === questionId);
    },
    goBack() {
      this.$router.push({ name: "PracticeSession" });
    },
    onFileLogoChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (files.length > 3) {
        window.M.toast({
          html: "You are only allowed to upload a maximum of 3 files at a time",
          //   classes: "rounded",
        });
      } else {
        if (!files.length) return;
        this.$store.dispatch("showLoader", true);
        this.fileList = files;
        this.$store.dispatch("showLoader", false);
        this.createImage(files);
      }
    },
    createImage(files) {
      this.$store.dispatch("showLoader", true);
      const fileDataTemp = [];
      for (let index = 0; index < files.length; index += 1) {
        // this.subQuesList[this.selectedIndex].uploadedImages.push(files[index]);
        const reader = new FileReader();
        reader.onload = () => {
          this.Base64File = reader.result.split(",")[1];
          const fileData = {
            fileData: this.Base64File,
            fileName: files[index].name,
          };
          this.subQuesList[this.selectedIndex].solutionUrlList.push(fileData);
          fileDataTemp.push(fileData);
        };
        reader.readAsDataURL(files[index]);
        this.fileListFinal = fileDataTemp;
      }
      //   this.subQuesList[this.selectedIndex].solutionUrlList.push(this.fileListFinal);
      //   const questionList = this.subQuesList[this.selectedIndex];
      //   this.subQuesList.splice(this.subQuesList[this.selectedIndex], this.selectedIndex, questionList);
      const questionData = {
        questionId: this.selectedQuestionId,
        maximumMarks: this.marks,
        files: this.fileListFinal,
      };
      this.subjectiveQuesList.push(questionData);
      this.$store.dispatch("showLoader", false);
      this.showImage = false;
      this.$forceUpdate();
    },
    SubmitSubDataTypeQuestionList() {
      const user = this.$store.getters.user;
      const data = {
        userTestSessionId: Number(this.userTestSessionId),
        affiliationId: Number(user.AffiliationId),
        batchId: Number(user.CenterCodeId),
        courseId: String(user.CourseId),
        subjectId: String(this.subjectId),
        studentId: Number(user.UserId),
        questionList: this.subQuesList,
      };
      this.$store.dispatch("showLoader", true);
      Mobileapi.SubmitSubjectiveQuestionList(data, (response) => {
        this.submitQues = response;
        let beginDate = moment();
        beginDate = beginDate.format("YYYY-MM-DD HH:MM");
        this.startDate = beginDate;
        const endsession = {
          userTestSessionId: this.userTestSessionId,
          serverToken: String(this.slToken),
        };
        this.$store.dispatch("showLoader", false);
        Mobileapi.Endsession(endsession, (response1) => {
          if (response1.responseCode === 200) {
            this.$router.push({ name: "PracticeHome" });
          }
        });
      });
    },
  },
  components: { Modal2 },
};
</script>

<style scoped>
.practice-session {
  font-family: "Open Sans" !important;
}
.ln-20 {
  line-height: 20px !important;
}
.ln-14 {
  line-height: 14px !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-600 {
  font-size: 600 !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.flex {
  display: flex !important;
}
.blk-text {
  color: #333333;
}
.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row !important;
}
.content-btw {
  justify-content: space-between !important;
}
.content-cntr {
  justify-content: center !important;
}
.content-even {
  justify-content: space-evenly !important;
}
.content-around {
  justify-content: space-around !important;
}
.content-left {
  justify-content: left !important;
}
.flex-end {
  justify-content: end !important;
}

.session-box {
  box-sizing: border-box;
  max-width: 1174px;
  min-width: 300px;
  height: 620px;
  border: 1px solid #e0e4f0;
  border-radius: 6px 6px 0px 0px;
  margin-top: 20px;
}
.header-box {
  box-sizing: border-box;
  position: relative;
  max-width: 1174px;
  min-width: 300px;
  height: 79px;
  background: #f3f5fc;
  border: 1px solid #e0e4f0;
  border-radius: 6px 6px 0px 0px;
}
.header-options {
  height: 42px;
  margin: 19px 20px 18px 22px;
}
.header-right {
  display: flex !important;
}
.header-right-button {
  width: 20px;
  height: 34px;
  background: #693383;
  border-radius: 4px;
  border: none;
  /* margin: 3px 10px 5px 0px; */
  margin-right: 10px;
}
.table-header {
  height: 42px;
  border-bottom: 1px solid #e0e4f0;
}
.table-header-content {
  margin-top: 13px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  /* identical to box height, or 117% */
  color: #333333;
  opacity: 0.7;
}
.table-row {
  width: 1070px;
  height: 55px;
  border-bottom: 1px solid #e0e4f0;
  margin-left: 52px !important;
  margin-top: 15px !important;
}
.table-row-index {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
}
.question-content::-webkit-scrollbar {
  display: none;
}
.question-image {
  width: 350px;
  height: 40px;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 48px;
}
.upload-button {
  width: 84px;
  height: 34px;
  background: #f3f5fc;
  border-radius: 4px;
  color: #3751ff;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  border: none;
}
.upload-button:hover {
  background: #3751ff !important;
  color: #f3f5fc !important;
}
.upload-image {
  width: 117px;
  height: 34px;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  background: #ffffff;
  /* margin-left:16px; */
  margin-right: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #3751ff;
}
.submit-button {
  width: 129px;
  height: 34px;
  background: #37841c;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  border: none;
  margin-top: 25px;
  cursor: pointer;
}
.submit-div {
  max-width: 1070px;
  height: 55px;
  margin-left: 52px !important;
}
.concept-button-1 {
  cursor: not-allowed;
  width: 84px;
  height: 34px;
  background: #f3f5fc;
  border-radius: 4px;
  color: #e0e4f0;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  border: none;
}
.concept-button-2 {
  cursor: not-allowed;
  width: 84px;
  height: 34px;
  background: #3751ff;
  border-radius: 4px;
  color: #e0e4f0;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  border: none;
}
.concept-modal {
  width: max-content;
  height: max-content;
  min-width: 300px;
}
.earn-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 20px 0;
  border-radius: 5px 5px 0 0;
}
.earn-close {
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}
.container {
  /* background: aliceblue; */
  padding: 0;
  margin: 0;
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  margin: auto;
}

.model-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000000;
  opacity: 0.7;
  z-index: 0;
  cursor: pointer;
}
.box {
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  height: 380px;
  max-width: 750px;
}

.imageBox {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  position: absolute;
  width: 100%;
}

.img-content {
  width: 483px !important;
  height: 600px !important;
  overflow-y: scroll;
  /* align-items: center !important; */
}

.arrowtab {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 750px;
  width: 100%;
}

.arrowtab img {
  cursor: pointer;
}

.viewImghide {
  opacity: 0;
  visibility: hidden;
}
</style>
